var columns = [{
  title: '反馈人UID',
  dataIndex: 'feedbackuid',
  key: 'feedbackuid',
  width: '20%',
  scopedSlots: {
    customRender: 'feedbackuid'
  },
  align: 'center'
}, {
  title: '反馈人昵称',
  dataIndex: 'feedbacknickname',
  key: 'feedbacknickname',
  width: '20%',
  scopedSlots: {
    customRender: 'feedbacknickname'
  },
  align: 'center'
}, {
  title: '时间(UTC)',
  dataIndex: 'feedbacktime',
  key: 'feedbacktime',
  width: '20%',
  scopedSlots: {
    customRender: 'feedbacktime'
  },
  align: 'center'
}, {
  title: '反馈数',
  dataIndex: 'feedbacktotal',
  key: 'feedbacktotal',
  width: '10%',
  scopedSlots: {
    customRender: 'feedbacktotal'
  },
  align: 'center'
}, {
  title: '状态',
  dataIndex: 'feedbackstatus',
  key: 'feedbackstatus',
  width: '10%',
  scopedSlots: {
    customRender: 'feedbackstatus'
  },
  align: 'center'
}, {
  title: '操作',
  key: 'actions',
  dataIndex: 'actions',
  fixed: 'right',
  width: 150,
  scopedSlots: {
    customRender: 'actions'
  },
  align: 'center'
}];
export { columns };