var searchKeys = [{
  key: 'dates',
  label: '创建时间(GMT+8)',
  placeholder: ["开始时间", "结束时间"],
  dates: true,
  rules: [{
    type: 'array',
    required: false,
    message: '请选择时间段!'
  }]
}, {
  key: "feedbackuid",
  label: "反馈人UID",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "feedbacknickname",
  label: '反馈人昵称',
  placeholder: '请输入',
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };